import React, { ReactElement, VoidFunctionComponent } from 'react'
import { Typography } from '@matillion/component-library'

import classes from '../Error.module.scss'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { ProblemDetails } from '../../../modules/SaaSScheduler'
import ListProblemDetails from './ListProblemDetails'

interface GenericErrorProps {
  errorDescription: string | ReactElement
}

const GenericError: VoidFunctionComponent<GenericErrorProps> = ({
  errorDescription
}) => {
  const { t } = useTranslation()
  const location = useLocation()
  const problemDetails = location.state as ProblemDetails | undefined
  const isErrorInDiv = typeof errorDescription !== 'string'

  return (
    <>
      <Typography as="h2" format="dts" className={classes.Error__Title}>
        <span className={classes.Error__Emphasis}>
          {t('translation:error_page.generic.sorry')}
        </span>{' '}
        {t('translation:error_page.generic.prompt')}
      </Typography>

      <Typography
        format="bcm"
        className={classes.Error__Description}
        as={isErrorInDiv ? 'p' : 'div'}
      >
        {!problemDetails && errorDescription}
        {problemDetails !== undefined && (
          <ListProblemDetails problemDetails={problemDetails} />
        )}
      </Typography>
    </>
  )
}

export default GenericError
