import React, { VoidFunctionComponent } from 'react'
import { ScheduleEditorTypes } from '../types'
import { useParams } from 'react-router-dom'
import { Typography } from '@matillion/component-library'
import { useTranslation } from 'react-i18next'

const EditExistingSchedule: VoidFunctionComponent<ScheduleEditorTypes> = () => {
  const { t } = useTranslation()

  const { scheduleId } = useParams()

  return (
    <>
      <Typography format="dts" as="h1">
        {t('translation:page.schedule_editor.title.edit')}
      </Typography>
      <Typography format="mc">
        {t('translation:page.schedule_viewer.subtitle', { scheduleId })}
      </Typography>
    </>
  )
}

export default EditExistingSchedule
