import React from 'react'
import { useTranslation } from 'react-i18next'
import { Grid, Section, Typography } from '@matillion/component-library'
import SchedulerAppPage from '../../components/SchedulerAppPage'
import { ScheduleManagerDataGrid, ScheduleManagerFilters } from './components'

import classes from './components/ScheduleManager.module.scss'
import config, { FeatureFlag } from 'config'

const ScheduleManager = () => {
  const { t } = useTranslation()

  return (
    <SchedulerAppPage>
      <Grid>
        <Section size={12}>
          <Typography format="dts" as="h1" className={classes.Page__Title}>
            {t('translation:schedule_manager.title')}
          </Typography>
          {config.isFlagSet(FeatureFlag.SCHEDULE_SEARCH_FILTER_FEATURE) && (
            <ScheduleManagerFilters />
          )}
          <ScheduleManagerDataGrid />
        </Section>
      </Grid>
    </SchedulerAppPage>
  )
}

export default ScheduleManager
