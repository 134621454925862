import React, { useEffect, useState, VoidFunctionComponent } from 'react'
import {
  Icon,
  Popover,
  Button,
  Field,
  Toggle,
  Label
} from '@matillion/component-library'
import classes from './ScheduleManager.module.scss'
import classnames from 'classnames'

import { useTranslation } from 'react-i18next'
import { ActionItemTypes } from './types'
import GenericModal from 'components/Modal/GenericModal'
import config, { FeatureFlag } from 'config'

const ActionItem: VoidFunctionComponent<ActionItemTypes> = ({
  isValidating,
  isError,
  onDelete
}) => {
  const { t } = useTranslation()
  const [isPopoverOpen, setIsPopoverOpen] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)

  useEffect(() => {
    if (isModalOpen) {
      // immediately close the popover when the modal is opened
      setIsPopoverOpen(false)
    }
  }, [isModalOpen])

  useEffect(() => {
    if (isError) {
      // close the modal e.g. if there is an error deleting a schedule
      setIsModalOpen(false)
    }
  }, [isError])

  return (
    <>
      <Popover
        isOpen={isPopoverOpen}
        align="end"
        anchor={
          <Button
            alt="white"
            data-testid="button-row"
            onClick={() => setIsPopoverOpen((prevState) => !prevState)}
            aria-label={t('translation:popover.trigger.openactionslabel')}
          >
            <Icon.Ellipsis className={classes.Icons} />
          </Button>
        }
        onClickOutside={() => setIsPopoverOpen(false)}
        padding={10}
        position={['bottom', 'left']}
        className={classes.Popover}
      >
        <div data-testid="popover">
          {config.isFlagSet(FeatureFlag.SCHEDULE_STATUS_FEATURE) && (
            <div
              className={classnames(classes.Panel, classes.Panel__ToggleEnable)}
            >
              <Label
                title={t('translation:popover.toggleenabled.enabled')}
                className={classes.Panel__ToggleEnable__Label}
              >
                <Field
                  name="enabled"
                  checked={true}
                  value="enabled"
                  inputComponent={Toggle}
                  disabled
                />
              </Label>
            </div>
          )}
          <div
            className={classnames(classes.Panel, classes.Panel__ActionItems)}
          >
            {config.isFlagSet(FeatureFlag.SCHEDULE_EDIT_FEATURE) && (
              <Button alt="text" className={classes.Panel__Button}>
                <Icon.Pencil className={classes.Icons} />
                {t('translation:schedule_manager.actions.edit')}
              </Button>
            )}
            <Button
              alt="text"
              onClick={() => setIsModalOpen(true)}
              data-testid="button-delete"
              className={classnames(
                classes.Panel__Button,
                classes['Panel__Button--danger']
              )}
            >
              <Icon.Trash className={classes.Icons} />
              {t('translation:schedule_manager.actions.delete')}
            </Button>
          </div>
        </div>
      </Popover>
      {isModalOpen && (
        <GenericModal
          content={t('translation:modal.deleteSchedule.content')}
          validate={t('translation:modal.deleteSchedule.validate')}
          onCancel={() => {
            setIsModalOpen(false)
          }}
          onValidate={onDelete}
          isValidating={isValidating}
        />
      )}
    </>
  )
}

export default ActionItem
