import {
  useFlags as useLDFlags,
  camelCaseKeys
} from 'launchdarkly-react-client-sdk'
import { useContext, useMemo } from 'react'

import { FlagContext } from '../context'
import { FlagSet } from '../types'

/**
 * Returns the default feature flags used to initialise
 * `FlagProvider`. Any keys will be camelCased, to match the
 * behaviour of LaunchDarkly's own `useFlags` hook.
 */
const useDefaultFlags = () => {
  const hubFlags = useContext(FlagContext)

  return useMemo(() => camelCaseKeys(hubFlags), [hubFlags])
}

/**
 * Returns the currently-active feature flags for this user.
 * If LaunchDarkly has not finished initialising, or is not available,
 * this will return the default set of flags that were passed to
 * `FlagProvider`.
 *
 *  useFlags can only be used beneath the [[FlagProvider]].
 *
 * @typeParam TFlags A describing your feature flags. If this is
 *                   not provided, a `Record<string, unknown>` will be
 *                   returned by default.
 * @returns An object containing the current feature flags for this
 *          user, and their values.
 * @category Hooks
 */
const useFlags = <TFlags>() => {
  const defaultFlags = useDefaultFlags()
  const ldFlags = useLDFlags()

  /* by default, flags is an empty object in LD and our own code;
   * merging the two will return a valid set of flags whether or
   * not the LD FlagProvider has been used */
  return useMemo(
    () =>
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      ({
        ...defaultFlags,
        ...ldFlags
      } as FlagSet<TFlags>),
    [defaultFlags, ldFlags]
  )
}

export default useFlags
