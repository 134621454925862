import React, { VoidFunctionComponent } from 'react'
import { Typography } from '@matillion/component-library'

import { TableViewerTypes } from './types'

export const TableViewer: VoidFunctionComponent<TableViewerTypes> = ({
  tableData
}) => {
  return (
    <Typography as="div" format="bcs">
      {tableData}
    </Typography>
  )
}
