import { Route, Routes, Navigate } from 'react-router-dom'
import {
  STUB_ROOT,
  STUB_ERRORS,
  STUB_LOGOUT,
  STUB_SCHEDULES,
  STUB_SCHEDULES_NEW
} from '../../constants/route.constants'
import { Logout } from '../../modules/Auth'
import ScheduleManager from '../../modules/ScheduleManager/ScheduleManager'
import ScheduleEditor from '../../modules/ScheduleEditor/ScheduleEditor'
import ErrorPage from '../../modules/ErrorPage/ErrorPage'
import { ErrorConstants } from '../../constants/error.constants'
import Error from '../Error/Error'

const AppMainRouting = () => {
  return (
    <Routes>
      <Route path={STUB_LOGOUT} element={<Logout />} />
      <Route path={STUB_SCHEDULES} element={<ScheduleManager />} />
      <Route path={STUB_SCHEDULES_NEW} element={<ScheduleEditor />} />

      <Route
        path={STUB_ROOT}
        element={<Navigate to={STUB_SCHEDULES} replace={true} />}
      />
      <Route
        path={STUB_ERRORS}
        element={<Error type={ErrorConstants.INTERNAL_SERVER_ERROR} />}
      />
      <Route path="*" element={<ErrorPage />} />
    </Routes>
  )
}

export default AppMainRouting
