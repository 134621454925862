import { Typography } from '@matillion/component-library'
import React, { ChangeEvent, FunctionComponent } from 'react'
import {
  CheckboxSelectorOption,
  CheckboxSelectorProps
} from './CheckboxSelector.types'

import classes from './CheckboxSelector.module.scss'
import SelectAllButton from './SelectAllButton'

const CheckboxSelector: FunctionComponent<CheckboxSelectorProps> = ({
  name,
  title,
  options,
  selectedOptionIds = [],
  onChange,
  onSelectAllChange
}) => {
  const isAllSelected =
    selectedOptionIds.length > 0 && selectedOptionIds.length === options.length

  return (
    <fieldset className={classes.CheckboxSelector}>
      <legend className={classes.hideVisually}>{title}</legend>
      <div className={classes.CheckboxSelector__container}>
        {options.map(({ id, label, ariaLabel }: CheckboxSelectorOption) => (
          <label
            className={classes.CheckboxSelector__label}
            key={`checkbox-label-${name}-${id}`}
            data-testid={`label-${name}-${id}`}
            htmlFor={`checkbox-${name}-${id}`}
          >
            <input
              type="checkbox"
              id={`checkbox-${name}-${id}`}
              data-testid={name}
              name={name}
              className={classes.CheckboxSelector__checkbox}
              aria-label={ariaLabel}
              checked={selectedOptionIds.includes(id)}
              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                onChange(id, event.target.checked)
              }
            />
            <Typography
              className={classes.CheckboxSelector__button}
              weight="bold"
              format="bcs"
            >
              {label}
            </Typography>
          </label>
        ))}
        <SelectAllButton
          isAllSelected={isAllSelected}
          onClick={() => {
            onSelectAllChange(isAllSelected)
          }}
        />
      </div>
    </fieldset>
  )
}

export { CheckboxSelector }
