import { FunctionComponent, PropsWithChildren } from 'react'
import { HubHeader, HubHomeLink } from '@matillion/hub-client'
import { Layout, MatillionLogo } from '@matillion/component-library'

const AppLayout: FunctionComponent<PropsWithChildren> = ({ children }) => (
  <Layout
    header={
      <HubHeader
        logo={
          <HubHomeLink>
            <MatillionLogo aria-label="Matillion" />
          </HubHomeLink>
        }
      />
    }
  >
    {children}
  </Layout>
)

export default AppLayout
