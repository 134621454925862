import React, { VoidFunctionComponent } from 'react'
import { Typography } from '@matillion/component-library'
import { formatDays } from 'modules/SaaSScheduler/utils/cronTransformer'

import { CronViewerTypes } from './types'

export const CronViewer: VoidFunctionComponent<CronViewerTypes> = ({
  cronExpression,
  timezone
}) => {
  return (
    <Typography as="div" format="bcs">
      {cronExpression && formatDays(cronExpression)} ({timezone})
    </Typography>
  )
}
