import { FunctionComponent, PropsWithChildren } from 'react'

import classes from './Modal.module.scss'

export const ModalActions: FunctionComponent<PropsWithChildren> = ({
  children
}) => {
  return (
    <div className={classes.Modal__Actions} data-testid="modal-actions">
      {children}
    </div>
  )
}
