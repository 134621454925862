import React, { VoidFunctionComponent } from 'react'
import classnames from 'classnames'

import {
  AutoComplete,
  Button,
  Field,
  Grid,
  Icon,
  MicroCta,
  Section,
  Tabs,
  Tab,
  Toaster,
  Toggle,
  Typography
} from '@matillion/component-library'
import { useTranslation } from 'react-i18next'

import { Formik } from 'formik'

import {
  CRON_ALL_VALUES,
  HOURS_DEFAULT,
  MINUTES_DEFAULT,
  daysOfTheWeek
} from './constants/scheduler.constants'
import {
  FormikValueTypes,
  Days,
  ProblemDetails,
  SaaSSchedulerProps
} from './types'

import classes from './SaaSScheduler.module.scss'
import { getDefaultTimezone } from './utils/timezones'

import useCreateSchedule from 'hooks/useCreateSchedule'
import { ReactQueryDevtools } from 'react-query/devtools'
import config, { FeatureFlag } from '../../config'
import { ValuesSchema } from './formValidation'
import { CheckboxSelector } from 'components/CheckboxSelector'
import { useNavigate } from 'react-router-dom'
import { AppRoutes } from 'constants/route.constants'
import { StatusCodes } from 'constants/status-code.constants'
import { DefinitionList } from 'components/DefinitionList'
import { getGroupedTimezones } from './utils/timezone-groups'
import { TIMEZONE_GROUPING } from 'constants/dateformat.constants'

const timezonesList = getGroupedTimezones(TIMEZONE_GROUPING)

const SaaSScheduler: VoidFunctionComponent<SaaSSchedulerProps> = ({
  job,
  onClose
}) => {
  const { t } = useTranslation()
  const createSchedule = useCreateSchedule()
  const navigate = useNavigate()
  const { makeToast, clearToasts } = Toaster.useToaster()

  const isFlagTest = config.isFlagSet(FeatureFlag.FEATURES_ONLY_FOR_DEBUGGING)

  const onlyAllowNumbers = (event: KeyboardEvent) => {
    if (!/\d/.test(event.key)) {
      event.preventDefault()
    }
  }

  const handleClose = (dirty: boolean) => {
    onClose(dirty)
  }

  const initialValues: FormikValueTypes = {
    name: '',
    timezone: getDefaultTimezone(),
    hours: HOURS_DEFAULT,
    minutes: MINUTES_DEFAULT,
    chooseWeekOrMonth: 'week',
    month: CRON_ALL_VALUES, // hidden in the form but kept in Formik for CRON validation
    weekDays: new Array<Days>(),
    monthDays: CRON_ALL_VALUES,
    preventDuplicate: true,
    expire: false,
    expireOn: '',
    scheduleEnabled: true
  }

  const publishSchedule = (
    values: FormikValueTypes,
    onPublishStatus: (status?: ProblemDetails | undefined) => void,
    onFinish: () => void
  ) => {
    createSchedule
      .mutateAsync({
        job,
        schedule: values
      })
      .then((e) => {
        // Maybe a bit too much to use a "problem" payload to pass a success
        onPublishStatus({
          type: 'forms.scheduler.submitted',
          status: e.status,
          title: e.statusText
        })
      })
      .catch(
        ({
          message,
          name,
          config: { url },
          response: { status } = { status: StatusCodes.INTERNAL_SERVER_ERROR }
        }) => {
          onPublishStatus({
            type: 'error.submitting',
            status,
            title: message,
            detail: name,
            instance: url
          })
        }
      )
      .finally(onFinish)
  }

  const matchStatusNotification = (information: ProblemDetails | undefined) => {
    if (information?.status === StatusCodes.CREATED) {
      makeToast({
        title: t('forms.scheduler.submitted_success_title'),
        message: t('forms.scheduler.submitted'),
        type: 'success',
        withFade: true,
        alt: 'red',
        theme: 'dark'
      })
      navigate(AppRoutes.getScheduleManager(), { replace: true })
    } else if (information?.status === StatusCodes.BAD_REQUEST) {
      makeToast({
        title: t('forms.scheduler.submitted_error_title'),
        message: t('forms.scheduler.hasErrors_one'),
        type: 'error',
        withFade: true,
        alt: 'red',
        theme: 'dark'
      })
    } else {
      navigate(AppRoutes.getGenericError(), {
        replace: true,
        state: information
      })
    }
  }

  return (
    <div
      className={classnames('MCL-Form__Container', classes.SaaSScheduler)}
      data-testid="scheduler"
    >
      <Grid>
        <Section size={12} subgrid>
          <Section size={6} offset={3}>
            <Typography className={classes.Header__title} format="dts" as="h1">
              {t('translation:page.schedule_editor.title.create')}
            </Typography>
          </Section>
          <Section className={classes.Header__actionRight} size={3}>
            <MicroCta
              alt="secondary"
              size="lg"
              aria-label={t('action.cancel')}
              onClick={() => handleClose(true)}
              data-testid="cancel-btn"
            >
              <Icon.Cross />
            </MicroCta>
          </Section>
        </Section>
        <Section size={6} className={classes.SaaSScheduler__Form}>
          <Formik
            initialValues={initialValues}
            enableReinitialize
            validationSchema={ValuesSchema}
            onSubmit={(values, { setSubmitting, setStatus }) => {
              clearToasts()
              setStatus(undefined)
              publishSchedule(
                values,
                (status) => {
                  setStatus(status)
                  matchStatusNotification(status)
                },
                () => {
                  setSubmitting(false)
                }
              )
            }}
            validateOnMount={false}
          >
            {({
              values,
              errors,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              isValid,
              setFieldValue,
              dirty
              /* and other goodies */
            }) => (
              <form onSubmit={handleSubmit}>
                <DefinitionList
                  data-testid="job-information"
                  listTitle={t('scheduler.jobInformation.title')}
                  items={[
                    {
                      title: t('scheduler.jobInformation.rootJob'),
                      description: job?.startFlow
                    },
                    {
                      title: t('scheduler.jobInformation.environment'),
                      description: job?.metlEnvironmentName
                    },
                    {
                      title: t('scheduler.jobInformation.dataPlane'),
                      description: job?.dataplaneName
                    }
                  ]}
                  className={classes.SaaSScheduler__JobInformation}
                />
                <fieldset>
                  <Field
                    title={t('scheduler.name.title')}
                    name="name"
                    data-testid="name-input"
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    supportText={t('scheduler.name.supportText')}
                    errorText={errors?.name ? t(errors.name) : null}
                    hasError={!!errors?.name}
                    required
                  />

                  {config.isFlagSet(FeatureFlag.SCHEDULE_STATUS_FEATURE) && (
                    <Field
                      name="scheduleEnabled"
                      data-testid="toggle-enable-schedule"
                      title={t('scheduler.scheduleDetails.enableSchedules')}
                      value={values.scheduleEnabled}
                      inputComponent={Toggle}
                      overlayText={{
                        on: t('scheduler.scheduleDetails.active'),
                        off: t('scheduler.scheduleDetails.paused')
                      }}
                      className={classnames(classes.EnableToggle, {
                        [classes['EnableToggle--Active']]:
                          values.scheduleEnabled
                      })}
                      checked={values.scheduleEnabled}
                      onChange={handleChange}
                    />
                  )}
                </fieldset>

                <fieldset className={classes.CronFieldContainer}>
                  <legend>{t('scheduler.scheduleDetails.title')}</legend>

                  <Field
                    title={t('scheduler.timezone.title')}
                    name="timezone"
                    data-testid="timezone-input"
                    value={values.timezone}
                    inputComponent={AutoComplete}
                    placeholder={t('scheduler.timezone.placeholder')}
                    onChange={handleChange}
                    availableItems={timezonesList}
                    resultsClassName={
                      TIMEZONE_GROUPING && classes.AutoCompleteGrouped
                    }
                    errorText={
                      errors?.timezone ? t(errors.timezone as string) : null
                    }
                    hasError={!!errors?.timezone}
                  />

                  <Field
                    title={t('scheduler.hours.title')}
                    name="hours"
                    data-testid="hours-input"
                    errorText={errors?.hours ? t(errors.hours) : null}
                    hasError={!!errors?.hours}
                    value={values.hours}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    supportText={t('scheduler.hours.supportText')}
                    required
                    className={classnames(
                      classes.SpacingStyles,
                      'container-hours'
                    )}
                    type={'number'}
                    min={0}
                    max={23}
                    onKeyPress={onlyAllowNumbers}
                  />

                  <Field
                    title={t('scheduler.minutes.title')}
                    name="minutes"
                    data-testid="minutes-input"
                    errorText={errors?.minutes ? t(errors.minutes) : null}
                    hasError={!!errors?.minutes}
                    value={values.minutes}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    supportText={t('scheduler.minutes.supportText')}
                    required
                    type={'number'}
                    className={classnames(
                      classes.SpacingStyles,
                      'container-minutes'
                    )}
                    min={0}
                    max={59}
                    onKeyPress={onlyAllowNumbers}
                  />

                  <div className={classes.ScheduleSelector}>
                    <Tabs tabFormat="stretch">
                      <Tab title={t('scheduler.timePeriod.dailySchedule')}>
                        <div className={classes.ChooseWeekOrMonth}>
                          <CheckboxSelector
                            name="weekDays"
                            title={t('scheduler.weekDays.title')}
                            options={daysOfTheWeek.map((day) => ({
                              id: day,
                              label: t(`scheduler.weekDays.${day}.title`),
                              ariaLabel: t(
                                'scheduler.selectDaysOfWeek.checkboxLabel',
                                {
                                  day: t(`scheduler.weekDays.${day}.longTitle`)
                                }
                              )
                            }))}
                            selectedOptionIds={values.weekDays}
                            onChange={(
                              optionId: string,
                              isChecked: boolean
                            ) => {
                              if (isChecked) {
                                setFieldValue('weekDays', [
                                  ...values.weekDays,
                                  optionId
                                ])
                              } else {
                                setFieldValue(
                                  'weekDays',
                                  values.weekDays.filter(
                                    (day) => day !== optionId
                                  )
                                )
                              }
                            }}
                            onSelectAllChange={(isAllSelected: boolean) => {
                              setFieldValue(
                                'weekDays',
                                isAllSelected ? [] : daysOfTheWeek
                              )
                            }}
                          />
                        </div>
                      </Tab>
                      <Tab
                        title={t('scheduler.timePeriod.monthlySchedule')}
                        disabled
                      ></Tab>
                    </Tabs>
                  </div>
                </fieldset>

                <Grid>
                  <Section className={classes.Actions} size={12}>
                    <Button
                      type="submit"
                      disabled={!dirty || !isValid || isSubmitting}
                      data-testid="done-btn"
                      size="lg"
                    >
                      <Typography format="bcs" weight="bold">
                        {t('forms.scheduler.saveSchedule')}
                      </Typography>
                    </Button>
                  </Section>
                </Grid>
              </form>
            )}
          </Formik>
          {isFlagTest && <ReactQueryDevtools initialIsOpen={false} />}
        </Section>
      </Grid>
    </div>
  )
}

export default SaaSScheduler
