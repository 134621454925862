import { useQuery } from 'react-query'
import { QueryKey } from '../constants/endpoint.constants'
import { getSchedules } from '../api/scheduler'
import { ScheduledETLJobsResponse } from '../api/schemas/types'
import { ScheduledETLJobTypes } from '../api/schemas/schedule-etl-job.types'
import { AxiosError } from 'axios'
import { useNetworkErrorToast } from './useNetworkErrorToast'

export const transformWithScheduleId = (data: ScheduledETLJobsResponse) =>
  data.schedules?.map((job: ScheduledETLJobTypes) => ({
    ...job,
    // An `id` is required by the DataGrid component to populate the `key` in the React tree
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    id: job.executeRequest.flowIdentifier.schedulerID!
  }))

const useGetSchedules = () => {
  const makeErrorToast = useNetworkErrorToast()

  return useQuery(
    QueryKey.SCHEDULE_LIST,
    async () => {
      const { data } = await getSchedules()
      return transformWithScheduleId(data)
    },
    {
      onError: (err: AxiosError) => {
        const { status } = err
        /* istanbul ignore next */
        if (!status) makeErrorToast(err.message)
      }
    }
  )
}

export default useGetSchedules
