import { FunctionComponent, ReactElement, useMemo } from 'react'
import { LDProvider } from 'launchdarkly-react-client-sdk'
import { ProviderConfig } from 'launchdarkly-react-client-sdk/lib/types'

import { FlagContext } from '../context'
import getConfig from '../config'

import useAuth from '../effects/useAuth'
import useUser from '../effects/useUser'
import useEnvironment from '../effects/useEnvironment'

import AsyncFlagProvider from './AsyncFlagProvider'

export interface FlagProviderProps {
  /**
   * The client ID for your application, as listed in the LaunchDarkly
   * admin panel. If left blank, the default Matillion application
   * will be used, but we recommend that you create your own.
   */
  launchDarklyClientId?: string

  /**
   * Default values for your feature flags, which will be used in your
   * application until LaunchDarkly has finished loading. When this happens,
   * the default values set in the admin panel will overwrite these defaults.
   *
   * If the `AuthProvider` is running in `AuthProviderEnvironment.test` mode,
   * LaunchDarkly will not be loaded, meaning that these default flags will be
   * used at all times.
   */
  flags: ProviderConfig['flags']

  /**
   * If set to true, any children of this component will not be rendered
   * until flag values have been loaded for the current viewer from
   * LaunchDarkly. Until then, a Loader will be displayed.
   */
  waitForFlags?: boolean

  /**
   * If set to true, the FlagProvider will launch in offline mode. This
   * will prevent it from connecting to LaunchDarkly, which means it will
   * only ever provide the default feature flags to any components calling
   * `useFlags`.
   *
   * By default, this will mirror the value of `offline` in the AuthProvider.
   */
  offline?: boolean

  children: ReactElement
}

const useLDUser = () => {
  const { isLoggedIn } = useAuth()
  const user = useUser()

  return useMemo(() => {
    if (!isLoggedIn) {
      return {
        key: 'unauthenticated-users',
        name: 'Unauthenticated Users',
        anonymous: true
      }
    }

    return {
      key: user.profile.email,
      email: user.profile.email,
      name: user.profile.name,
      avatar: user.profile.icon,
      custom: {
        organisationName: user.organisation.name,
        organisationId: user.organisation.id,
        roles: Array.from(user.roles)
      },
      anonymous: false
    }
  }, [isLoggedIn, user])
}

/**
 * The FlagProvider connects to LaunchDarkly to provide feature flagging to your
 * application. It will automatically use the current auth0 user's details to
 * connect to LaunchDarkly, meaning that any applicable targeting will be
 * applied by default.
 *
 * If the user is not logged in, or the FlagProvider is rendered before the
 * AuthProvider has finished loading, LaunchDarkly will generate an ID for the
 * session on their side. Once the user has finished logging in, FlagProvider
 * will automatically update their details with LaunchDarkly, too, to ensure
 * that targeting is kept up-to-date.
 *
 * The FlagProvider must be used beneath the [[AuthProvider]].
 *
 * Example:
 * ```
  const App = ({children}) => {
   const enableExampleFlagByDefault = true

   return (
     <FlagProvider flags={{"example-flag": enableExampleFlagByDefault}}>
      {children}
    </FlagProvider>)
   }
 * ```
 *
 * @param props See [[FlagProviderProps]].
 * @category Components
 */
const FlagProvider: FunctionComponent<
  React.PropsWithChildren<FlagProviderProps>
> = ({
  launchDarklyClientId,
  flags = {},
  waitForFlags = false,
  offline,
  children
}) => {
  const { environment, offline: isAuthProviderOffline } = useEnvironment()
  const defaultClientId = useMemo(
    () => getConfig(environment).launchDarkly.defaultClientId,
    [environment]
  )

  const ldUser = useLDUser()
  const inOfflineMode =
    offline !== undefined ? !!offline : isAuthProviderOffline

  const providerProps: ProviderConfig = {
    clientSideID: launchDarklyClientId ?? defaultClientId,
    flags,
    user: ldUser
  }

  if (inOfflineMode) {
    return <FlagContext.Provider value={flags}>{children}</FlagContext.Provider>
  }

  return waitForFlags ? (
    <AsyncFlagProvider providerProps={providerProps}>
      {children}
    </AsyncFlagProvider>
  ) : (
    <LDProvider {...providerProps}>{children}</LDProvider>
  )
}

export default FlagProvider
