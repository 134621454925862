import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import config from 'config'
import {
  QUERY_SMART_TIMEOUT,
  URL_CREATE_SCHEDULE,
  URL_DELETE_SCHEDULE,
  URL_EDIT_SCHEDULE,
  URL_GET_SCHEDULES
} from '../constants/endpoint.constants'
import { ScheduledETLJobTypes } from './schemas/schedule-etl-job.types'
import {
  CreateScheduleRequestHeaders,
  DeleteScheduleRequest,
  ScheduledETLJobsResponse
} from './schemas/types'

export const schedulerApi = axios.create({
  baseURL: config.apiHost,
  timeout: QUERY_SMART_TIMEOUT
})

export async function getSchedules(
  requestConfig?: AxiosRequestConfig
): Promise<AxiosResponse<ScheduledETLJobsResponse>> {
  return schedulerApi.get<ScheduledETLJobsResponse>(URL_GET_SCHEDULES, {
    ...requestConfig
  })
}

export async function createSchedule(
  schedule: ScheduledETLJobTypes,
  headers: CreateScheduleRequestHeaders,
  requestConfig?: AxiosRequestConfig
): Promise<AxiosResponse<ScheduledETLJobTypes>> {
  return schedulerApi.post<ScheduledETLJobTypes>(
    URL_CREATE_SCHEDULE,
    { ...schedule },
    {
      headers,
      ...requestConfig
    }
  )
}

export async function deleteSchedule(
  schedule: DeleteScheduleRequest,
  requestConfig?: AxiosRequestConfig
): Promise<AxiosResponse> {
  const params = new URLSearchParams({
    schedulerID: schedule.schedulerID
  })

  return schedulerApi.delete(`${URL_DELETE_SCHEDULE}?${params.toString()}`, {
    ...requestConfig
  })
}

export async function editSchedule(schedule: ScheduledETLJobTypes) {
  return schedulerApi.put(URL_EDIT_SCHEDULE, schedule)
}
