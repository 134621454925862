import { Typography } from '@matillion/component-library'
import React, { FunctionComponent } from 'react'
import classes from './DefinitionList.module.scss'
import classnames from 'classnames'

interface DefinitionListItem {
  title: string
  description: string | React.ReactElement
}

interface DefinitionListProps {
  listTitle: string
  items: DefinitionListItem[]
  className?: string
}

const DefinitionList: FunctionComponent<DefinitionListProps> = ({
  listTitle,
  items,
  className,
  ...otherProps
}) => {
  return (
    <dl
      title={listTitle}
      className={classnames(classes.DefinitionList, className)}
      {...otherProps}
    >
      {items.map(({ title, description }) => (
        <div className={classes.DefinitionList__item} key={title}>
          <dt className={classes.DefinitionList__title}>
            <Typography format="bcm" as="span">
              {title}
            </Typography>
          </dt>
          <dd className={classes.DefinitionList__description}>
            <Typography format="bcm" as="span">
              {description}
            </Typography>
          </dd>
        </div>
      ))}
    </dl>
  )
}

export { DefinitionList }
