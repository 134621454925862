import React, { ReactElement, VoidFunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'
import { Typography } from '@matillion/component-library'

import classes from './Error.module.scss'

import GenericError from './components/GenericError'
import WrongPageError from './components/WrongPageError'
import { ErrorConstants, isCustomError } from '../../constants/error.constants'

interface ErrorProps {
  type: ErrorConstants | string | ReactElement
}

const errors: Record<ErrorConstants, ReactElement> = {
  [ErrorConstants.WRONG_PAGE]: <WrongPageError />,
  [ErrorConstants.INTERNAL_SERVER_ERROR]: (
    <GenericError
      errorDescription={'ErrorConstants.INTERNAL_SERVER_ERROR'}
      data-testid="generic-error"
    />
  )
}

const Error: VoidFunctionComponent<ErrorProps> = ({ type }) => {
  const { t } = useTranslation()

  const error = (typeof type === 'string' &&
    isCustomError(type) &&
    errors[type as ErrorConstants]) || (
    <GenericError errorDescription={type} data-testid="generic-error" />
  )

  return (
    <div className={classes.Error}>
      <div className={classes.Error__Content}>{error}</div>
      <Typography format="mc" className={classes.Error__ContactUs}>
        {t('translation:error_page.generic.help')}{' '}
        <a
          href="https://www.matillion.com/about/contact/"
          target="_blank"
          rel="noopener noreferrer"
          className={classes.Error__ContactUs__Link}
          data-testid="contact-link"
        >
          {t('translation:error_page.generic.contact')}
        </a>
      </Typography>
    </div>
  )
}

export default Error
