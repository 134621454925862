import React from 'react'
import { Button, Typography } from '@matillion/component-library'
import classes from '../Error.module.scss'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const WrongPageError = () => {
  const { t } = useTranslation()

  return (
    <>
      <Typography as="h1" format="dtm" className={classes.Error__Title}>
        <span className={classes.Error__Emphasis}>
          {t('translation:error_page.generic.sorry')}
        </span>{' '}
        {t('translation:error_page.generic.prompt')}
      </Typography>

      <Typography format="bcl" className={classes.Error__Description}>
        {t('translation:error_page.wrong_page')}
      </Typography>

      <Link to="/">
        <Button
          alt="primary"
          size="lg"
          className={classes.Error__Action}
          data-testid="back-to-login-btn"
          text={t('translation:error_page.generic.return_home')}
        />
      </Link>
    </>
  )
}

export default WrongPageError
