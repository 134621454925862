import React, { VoidFunctionComponent } from 'react'
import { DataGrid, LoadingSpinner, Toaster } from '@matillion/component-library'
import { useTranslation } from 'react-i18next'
import { CronViewer } from './CronViewer'
import { NameViewer } from './NameViewer'

import ActionItem from './ActionItem'
import classes from './ScheduleManager.module.scss'
import { ReactQueryDevtools } from 'react-query/devtools'
import config, { FeatureFlag } from '../../../config'
import useGetSchedules from 'hooks/useGetSchedules'
import { ScheduledETLJobWithIDTypes } from '../../../api/schemas/schedule-etl-job.types'
import useDeleteSchedule from 'hooks/useDeleteSchedule'
import { ActionItemTypes } from './types'
import useSortSchedules, { ColumnKeys } from '../useSortSchedules'
import { formatDate } from '../../../constants/dateformat.constants'
import { TableViewer } from './TableViewer'

const ScheduleManagerDataGrid: VoidFunctionComponent = () => {
  const { t } = useTranslation()
  const { isLoading, error, data: schedules } = useGetSchedules()
  const {
    isLoading: isDeletingSchedule,
    isError: isDeleteScheduleError,
    mutateAsync: deleteSchedule
  } = useDeleteSchedule()
  const isFlagTest = config.isFlagSet(FeatureFlag.FEATURES_ONLY_FOR_DEBUGGING)
  const { makeToast } = Toaster.useToaster()
  const { sortedSchedules, setSortState } = useSortSchedules(schedules)

  const onEdit = (uuid: string) => console.log('todo onEdit', uuid)

  const handleDelete = async (uuid: string) => {
    const item = schedules?.find(
      (e) => e.executeRequest.flowIdentifier.schedulerID === uuid
    )

    if (!item) return

    const {
      schedule: { name: scheduleName }
    } = item

    try {
      await deleteSchedule({
        schedulerID: uuid
      })

      makeToast({
        title: t('forms.scheduler.deleted_schedule_success_title'),
        message: t('forms.scheduler.deleted_schedule_success', {
          scheduleName
        }),
        type: 'success',
        theme: 'dark',
        withFade: true
      })
    } catch {
      makeToast({
        title: t('forms.scheduler.deleted_schedule_error_title'),
        message: t('forms.scheduler.deleted_schedule_error', {
          scheduleName
        }),
        type: 'error',
        theme: 'dark',
        withFade: true
      })
    }
  }
  const onDuplicate = (uuid: string) => console.log('onDuplicate', uuid)

  if (isLoading) {
    return <LoadingSpinner data-testid="schedule-loading-spinner" />
  }

  if (error) {
    const { message } = error
    return <div>{message}</div>
  }

  return (
    <>
      <DataGrid
        data-testid="schedule-data-grid"
        columns={[
          {
            key: ColumnKeys.scheduleName,
            title: t('translation:schedule_manager.column.name'),
            mapValues: (row: ScheduledETLJobWithIDTypes) => ({
              nameData: row.schedule.name
            }),
            sortable: true,
            as: NameViewer
          },
          {
            key: ColumnKeys.schedule,
            title: t('translation:schedule_manager.column.cron'),
            mapValues: (row: ScheduledETLJobWithIDTypes) => ({
              cronExpression: row.schedule.cronExpression,
              timezone: row.schedule.timezone
            }),
            as: CronViewer,
            sortable: true
          },
          {
            key: ColumnKeys.lastRun,
            title: t('translation:schedule_manager.column.lastRun'),
            mapValues: (row: ScheduledETLJobWithIDTypes) => ({
              tableData: formatDate(
                row.schedule.lastRun as string,
                t('schedule_manager.rows.emptyDateValue')
              )
            }),
            sortable: true,
            as: TableViewer
          },
          {
            key: ColumnKeys.nextRun,
            title: t('translation:schedule_manager.column.nextRun'),
            mapValues: (row: ScheduledETLJobWithIDTypes) => ({
              tableData: formatDate(
                row.schedule.nextRun as string,
                t('schedule_manager.rows.emptyDateValue')
              )
            }),
            sortable: true,
            as: TableViewer
          },
          {
            key: ColumnKeys.rootJob,
            title: t('translation:schedule_manager.column.startFlow'),
            mapValues: (row: ScheduledETLJobWithIDTypes) => ({
              tableData: row.executeRequest.flowIdentifier.startFlow
            }),
            sortable: true,
            as: TableViewer
          },
          {
            key: ColumnKeys.version,
            title: t('translation:schedule_manager.column.version'),
            mapValues: (row: ScheduledETLJobWithIDTypes) => ({
              tableData: row.executeRequest.flowIdentifier.version
                ? row.executeRequest.flowIdentifier.version.toString()
                : ''
            }),
            sortable: true,
            as: TableViewer
          },
          {
            key: ColumnKeys.environment,
            title: t('translation:schedule_manager.column.metlEnvironmentName'),
            mapValues: (row: ScheduledETLJobWithIDTypes) => ({
              tableData: row.executeRequest.metlEnvironmentName
            }),
            sortable: true,
            as: TableViewer
          },
          {
            key: 'actions',
            title: t('translation:schedule_manager.column.actions'),
            mapValues: (row: ScheduledETLJobWithIDTypes): ActionItemTypes => {
              const scheduleId = row.executeRequest.flowIdentifier
                .schedulerID as string

              return {
                isValidating: isDeletingSchedule,
                isError: isDeleteScheduleError,
                onEdit: () => onEdit(scheduleId),
                onDelete: async () => handleDelete(scheduleId),
                onDuplicate: () => onDuplicate(scheduleId)
              }
            },
            as: ActionItem,
            className: classes.Actions__Container
          }
        ]}
        rows={sortedSchedules}
        onSort={setSortState}
      />
      {isFlagTest && <ReactQueryDevtools initialIsOpen={false} />}
    </>
  )
}

export default ScheduleManagerDataGrid
