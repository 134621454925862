import timezonesList from 'timezones-list'
import { TimezoneElementTypes, TimezoneListTypes } from '../types'
import { DEFAULT_TIMEZONE } from '../constants/scheduler.constants'

export const getDefaultTimezone = (): TimezoneElementTypes => {
  let defaultValue
  try {
    // see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/resolvedOptions
    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone

    const mainUserTimeZone = timezonesList.find(
      (e) => e.tzCode === userTimezone
    )

    defaultValue = mainUserTimeZone
      ? {
          id: mainUserTimeZone.tzCode,
          name: mainUserTimeZone.tzCode
        }
      : undefined
  } catch (e) {
    defaultValue = undefined
  }

  if (!defaultValue) {
    return DEFAULT_TIMEZONE
  }

  return defaultValue
}

export const getTimezonesList = (): TimezoneListTypes[] => {
  const UTC = {
    label: 'Etc/UTC (GMT+00:00)',
    tzCode: 'Etc/UTC',
    name: '(GMT+00:00) UTC',
    utc: '+00:00'
  }
  return [...timezonesList, UTC]
}
