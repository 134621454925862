import { FunctionComponent } from 'react'
import { PlatformMenuServiceItem } from '@matillion/component-library'

import useMatillionApplicationURL from '../../effects/useMatillionApplicationURL'

interface HubPlatformMenuServiceItemProps {
  name: string
  description: string
  href: string
}

const HubPlatformMenuServiceItem: FunctionComponent<
  HubPlatformMenuServiceItemProps
> = ({ name, description, href }) => {
  const { url, isActiveApplication } = useMatillionApplicationURL(href)

  return (
    <PlatformMenuServiceItem
      as="a"
      active={isActiveApplication}
      href={url.toString()}
      name={name}
      description={description}
    />
  )
}

export default HubPlatformMenuServiceItem
