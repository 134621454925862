export const STUB_ROOT = '/'
export const STUB_LOGOUT = '/logout'
export const STUB_SCHEDULES = '/dashboard'
export const STUB_SCHEDULES_NEW = '/create'
export const STUB_ERRORS = '/errors'

export const AppRoutes = {
  getLogout: () => STUB_LOGOUT,
  getDashboard: () => STUB_ROOT,
  getScheduleManager: () => STUB_SCHEDULES,
  getCreateSchedule: (searchParams: URLSearchParams) =>
    `${STUB_SCHEDULES_NEW}?${searchParams.toString()}`,
  getViewSchedule: (id: number) => `${STUB_SCHEDULES}/${id.toString()}`,
  getGenericError: () => STUB_ERRORS
}
