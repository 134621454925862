import { QuartzExpressionTypes } from '../types'

export const createCronExpression = ({
  minutes,
  hours,
  chooseWeekOrMonth,
  weekDays,
  monthDays
}: QuartzExpressionTypes): string => {
  const isMonthlySchedule = chooseWeekOrMonth === 'month'
  const cronDaysOfTheMonth = isMonthlySchedule ? monthDays : '?'
  const cronWeekDays =
    chooseWeekOrMonth === 'week' && weekDays.length > 0
      ? weekDays.join(',')
      : '*'

  // Cron Expression Format:
  // {seconds} {minutes} {hours} {day of month e.g. 1} {months e.g. Jan,Feb} {days of week e.g. Mon,Tues}
  // Example: 0 0 12 ? * Mon,Tue
  // At 12:00:00pm, on every Monday and Tuesday, every month
  if (isMonthlySchedule) {
    // TODO: This may need to be refactored when the monthly schedule UI has been created
    // and we know what schedule selection looks like
    return `0 ${minutes} ${hours} ${cronDaysOfTheMonth} * ? *`
  }

  return `0 ${minutes} ${hours} ? * ${cronWeekDays} *`
}
