import React, { VoidFunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'
import {
  AutoComplete,
  Grid,
  SearchBar,
  Section
} from '@matillion/component-library'

import classes from './ScheduleManager.module.scss'

const ScheduleManagerFilters: VoidFunctionComponent = () => {
  const { t } = useTranslation()

  return (
    <Grid>
      <Section size={3} className={classes.Filters__Main}>
        <AutoComplete
          availableItems={[]}
          name="service"
          onChange={() => undefined}
          placeholder={t(
            'translation:schedule_manager.filters.service.placeholder'
          )}
          value={null}
        />
      </Section>

      <Section size={3} className={classes.Filters__Search}>
        <SearchBar
          aria-label={t(
            'translation:schedule_manager.filters.search.ariaLabel'
          )}
          placeholder={t(
            'translation:schedule_manager.filters.search.placeholder'
          )}
          onChange={() => undefined}
        />
      </Section>
    </Grid>
  )
}

export default ScheduleManagerFilters
