import React, { FunctionComponent, ReactNode } from 'react'
import { Header, MatillionLogo } from '@matillion/component-library'

import HubHomeLink from './components/HubHomeLink'
import HubUserMenu from './HubUserMenu'
import HubPlatformMenu from './HubPlatformMenu'

export interface HubHeaderProps {
  /**
   * An optional logo, which overrides the HubHeader's default
   * if provided. It's recommended that the default logo is used,
   * as it links back to the Matillion Start application, which
   * is considered the user's "home".
   */
  logo?: ReactNode
}

/**
 * The HubHeader renders the entire Matillion application header. This
 * includes the Matillion logo/home link, the User Menu, and the
 * Platform Navigation menu.
 *
 * The FlagProvider must be used beneath the [[AuthProvider]].
 *
 * @param props See [[HubHeaderProps]].
 * @category Components
 */
const HubHeader: FunctionComponent<HubHeaderProps> = ({ logo }) => (
  <Header
    platformMenu={<HubPlatformMenu />}
    menu={<HubUserMenu />}
    logo={
      logo ?? (
        <HubHomeLink>
          <MatillionLogo />
        </HubHomeLink>
      )
    }
  />
)

export default HubHeader
