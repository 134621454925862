import { TimezoneGrouping } from '../modules/SaaSScheduler/utils/timezone-groups'
import format from 'date-fns/format'

// e.g. 2022-07-18 13:58 +01:00
// https://date-fns.org/v2.28.0/docs/format
const DISPLAY_DATE_FORMAT = 'yyyy-MM-dd HH:mm xxx'

export const formatDate = (date: string, noDateDisplayText: string): string => {
  if (!date) {
    return noDateDisplayText
  }

  return format(new Date(date), DISPLAY_DATE_FORMAT)
}

export const TIMEZONE_GROUPING: TimezoneGrouping = 'byRegion'
