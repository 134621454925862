import { useSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import config, { FeatureFlag } from '../config'
import { QUERY_KEY_ENCODED } from '../constants/endpoint.constants'
import {
  createScheduleRequestSchema,
  CreateScheduleQueryParams
} from '../api/schemas/metl-link.types'
import { ValidationError } from 'yup'

interface SearchParamsValidationType {
  job?: CreateScheduleQueryParams
  errors?: Partial<ValidationError>
}

export const useExtractJobFromRouteParams = (): SearchParamsValidationType => {
  const [searchParams] = useSearchParams()
  const { t } = useTranslation()

  let actual
  if (!config.isFlagSet(FeatureFlag.QUERY_PAYLOAD_BASE64)) {
    const entries = Array.from(searchParams)
    actual = entries.reduce((previousValue, [key, value]) => {
      return { ...previousValue, [key]: decodeURIComponent(value) }
    }, {})
  } else {
    const encoded = searchParams.get(QUERY_KEY_ENCODED)
    if (!encoded) {
      return {
        errors: {
          message: t('translation:schedule_editor.error.query_param_missing')
        }
      }
    }

    try {
      actual = JSON.parse(atob(encoded))
    } catch (e) {
      return {
        errors: {
          message: t('translation:schedule_editor.error.query_payload_decoding')
        }
      }
    }
  }

  let validation
  try {
    validation = createScheduleRequestSchema.validateSync(actual, {
      abortEarly: false,
      strict: true
    })
  } catch (e) {
    const { errors, message, value } = e as ValidationError
    return {
      job: undefined,
      errors: { errors, message, value }
    }
  }
  return { job: validation, errors: undefined }
}
