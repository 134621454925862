import { useMutation, useQueryClient } from 'react-query'

import { QueryKey } from '../constants/endpoint.constants'
import { ScheduleRequest, makeMutationData } from './mutation-utils'
import { CreateScheduleRequestHeaders } from '../api/schemas/types'
import { useUser } from '@matillion/hub-client'
import { AxiosError } from 'axios'
import { useNetworkErrorToast } from './useNetworkErrorToast'
import { createSchedule } from 'api/scheduler'

const useCreateSchedule = () => {
  const queryClient = useQueryClient()
  const { user } = useUser()
  const makeErrorToast = useNetworkErrorToast()

  return useMutation(
    QueryKey.CREATE_SCHEDULE,
    async (request: ScheduleRequest) => {
      const newSchedule = makeMutationData(request)
      const requestHeaders: CreateScheduleRequestHeaders = {
        'correlation-id': request.job.correlationId,
        'source-app': request.job.sourceApp,
        username: user.name
      }

      return createSchedule(newSchedule, requestHeaders)
    },
    {
      onSettled: () => {
        queryClient.invalidateQueries(QueryKey.SCHEDULE_LIST)
      },
      onError: (err: AxiosError) => {
        const { status } = err
        /* istanbul ignore next */
        if (!status) makeErrorToast(err.message)
      }
    }
  )
}

export default useCreateSchedule
