import React, { FunctionComponent, PropsWithChildren } from 'react'
import { Page, Section } from '@matillion/component-library'
import classes from './SchedulerAppPage.module.scss'

const SchedulerAppPage: FunctionComponent<PropsWithChildren> = ({
  children
}) => {
  return (
    <Page className={classes.Page}>
      <Section className={classes.Page__Section} aria-live="polite">
        {children}
      </Section>
    </Page>
  )
}

export default SchedulerAppPage
