import React, { VoidFunctionComponent } from 'react'
import { ProblemDetails } from '../../../modules/SaaSScheduler'
import { useTranslation } from 'react-i18next'
import classes from '../Error.module.scss'
import { Typography } from '@matillion/component-library'

interface ListProblemDetailProps {
  problemDetails: ProblemDetails
}
const ListProblemDetails: VoidFunctionComponent<ListProblemDetailProps> = ({
  problemDetails
}) => {
  const { t } = useTranslation()

  if (!problemDetails) return null

  return (
    <div className={classes.ProblemDetails} data-testid={'problem-details'}>
      <Typography format="bcm" className={classes.Error__Description} as={'p'}>
        {t(problemDetails.type)}
      </Typography>

      <dl>
        <dt>{t('translation:error_page.problem_details.title')}</dt>
        <dd>{problemDetails.title}</dd>

        <dt>{t('translation:error_page.problem_details.detail')}</dt>
        <dd>{problemDetails.detail}</dd>
      </dl>
    </div>
  )
}

export default ListProblemDetails
