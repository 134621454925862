import { useMemo, useState } from 'react'
import { SortState, SortOpts } from '@matillion/component-library'
import { ScheduledETLJobWithIDTypes } from 'api/schemas/schedule-etl-job.types'
import { formatDays } from 'modules/SaaSScheduler/utils/cronTransformer'

export enum ColumnKeys {
  environment = 'environment',
  lastRun = 'lastRun',
  nextRun = 'nextRun',
  rootJob = 'rootJob',
  scheduleName = 'scheduleName',
  schedule = 'schedule',
  version = 'version',
  agent = 'agent'
}

export type SortKeys = { [key in ColumnKeys]: SortOpts }

const useSortSchedules = (schedules: ScheduledETLJobWithIDTypes[] = []) => {
  const [sortState, setSortState] = useState<
    Partial<SortState<SortKeys>> | undefined
  >(undefined)

  const sortedSchedules = useMemo(() => {
    if (!sortState) {
      return schedules
    }

    const [sortKey, sortOrder] = Object.entries(sortState)[0]

    const sortFn = (
      item1: ScheduledETLJobWithIDTypes,
      item2: ScheduledETLJobWithIDTypes
    ) => {
      switch (sortKey) {
        case ColumnKeys.scheduleName: {
          return compare(item1.schedule.name, item2.schedule.name)
        }
        case ColumnKeys.schedule: {
          return compareSchedule(
            item1.schedule.cronExpression,
            item2.schedule.cronExpression
          )
        }
        case ColumnKeys.rootJob: {
          return compare(
            item1.executeRequest.flowIdentifier.startFlow,
            item2.executeRequest.flowIdentifier.startFlow
          )
        }
        case ColumnKeys.environment: {
          return compare(
            item1.executeRequest.metlEnvironmentName,
            item2.executeRequest.metlEnvironmentName
          )
        }
        case ColumnKeys.lastRun: {
          return compare(
            item1.schedule.lastRun ?? '-',
            item2.schedule.lastRun ?? '-'
          )
        }
        case ColumnKeys.nextRun: {
          return compare(
            item1.schedule.nextRun ?? '-',
            item2.schedule.nextRun ?? '-'
          )
        }
        case ColumnKeys.version: {
          return compare(
            item1.executeRequest.flowIdentifier.version
              ? item1.executeRequest.flowIdentifier.version.toString()
              : Number.MAX_SAFE_INTEGER.toString(),
            item2.executeRequest.flowIdentifier.version
              ? item2.executeRequest.flowIdentifier.version.toString()
              : Number.MAX_SAFE_INTEGER.toString(),
            { numeric: true }
          )
        }
        case ColumnKeys.agent: {
          return compare(
            item1.executeRequest.flowIdentifier.environment,
            item2.executeRequest.flowIdentifier.environment
          )
        }
        default: {
          throw new Error('Unhandled sort key in useSortSchedules')
        }
      }
    }

    return [...schedules].sort(sortFn)

    function compare(
      item1: string,
      item2: string,
      options?: { numeric: boolean }
    ): number {
      if (sortOrder === 'ASC') {
        return item1.localeCompare(item2, undefined, options)
      }
      return item2.localeCompare(item1, undefined, options)
    }

    function compareSchedule(
      item1: string | undefined,
      item2: string | undefined
    ): number {
      if (!(item1 && item2)) {
        return 0
      }

      const item1FormattedCron = formatDays(item1)
      const item2FormattedCron = formatDays(item2)

      return compare(item1FormattedCron, item2FormattedCron)
    }
  }, [schedules, sortState])

  return {
    sortedSchedules,
    setSortState
  }
}

export default useSortSchedules
