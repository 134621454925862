import { VoidFunctionComponent } from 'react'

import SchedulerAppPage from '../../components/SchedulerAppPage'
import { ScheduleAction, ScheduleEditorTypes } from './types'
import CreateNewSchedule from './components/CreateNewSchedule'
import EditExistingSchedule from './components/EditExistingSchedule'

const ScheduleEditor: VoidFunctionComponent<ScheduleEditorTypes> = ({
  action = ScheduleAction.NEW
}) => (
  <SchedulerAppPage>
    {action === ScheduleAction.NEW ? (
      <CreateNewSchedule />
    ) : (
      <EditExistingSchedule />
    )}
  </SchedulerAppPage>
)

export default ScheduleEditor
