import { useLayoutEffect } from 'react'
import { AxiosInstance } from 'axios'
import { useAuth, useUser } from '@matillion/hub-client'
import config from '../config'
import { useTranslation } from 'react-i18next'

const useAuthInterceptor = (api: AxiosInstance) => {
  const { getToken } = useAuth()
  const { organisation } = useUser()
  const { t } = useTranslation()

  useLayoutEffect(() => {
    const id = api.interceptors.request.use(async (request) => {
      const token = await getToken({
        audience: config.apiTokenAudience
      })

      request.headers = {
        ...request.headers,
        authorization: `Bearer ${token}`,
        'account-id': organisation.id
      }

      request.baseURL = config.apiHost.replace(
        '{{region}}',
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        organisation.region!
      )

      return request
    })

    return () => {
      api.interceptors.request.eject(id)
    }
  }, [api, getToken, organisation])

  useLayoutEffect(() => {
    api.defaults.timeoutErrorMessage = t('translation:error.query.timeout')
  }, [api, t])
}

export default useAuthInterceptor
