import { FunctionComponent, useEffect, useState } from 'react'
import {
  PlatformMenu,
  HeaderMenuItemGroup,
  HeaderMenuItem
} from '@matillion/component-library'

import usePlatformMenuStructure, {
  PlatformMenuService
} from './effects/usePlatformMenuStructure'

import HubPlatformMenuServiceItem from './HubPlatformMenuServiceItem'
import useEntitlements from '../../effects/useEntitlements'
import useUser from '../../effects/useUser'

const HubPlatformMenu: FunctionComponent = () => {
  const [{ services: standardServices, conditionalServices, groups }] =
    usePlatformMenuStructure()
  const { data: entitlements } = useEntitlements()
  const canAccessDesigner = entitlements?.canAccessDesigner
  const { roles } = useUser()
  const isSaasEtlUser = roles.has('saas-etl:user')
  const [services, setServices] = useState<PlatformMenuService[]>([])

  const createNewServicesList = (key: string, pos: number) => {
    const conditionalService = conditionalServices.find(
      (obj) => obj.key === key
    )

    let newServicesList: PlatformMenuService[] = []

    if (conditionalService) {
      newServicesList = [
        ...standardServices.slice(0, pos),
        conditionalService,
        ...standardServices.slice(pos)
      ]
    }

    return newServicesList
  }

  useEffect(() => {
    // Add the Designer service conditionally
    if (isSaasEtlUser && canAccessDesigner) {
      setServices(createNewServicesList('project-explorer-frontend', 1))
    } else {
      setServices(standardServices)
    }
  }, [standardServices, conditionalServices, canAccessDesigner, isSaasEtlUser])

  return (
    <PlatformMenu>
      <HeaderMenuItemGroup>
        {services.map((service) => (
          <HubPlatformMenuServiceItem
            key={service.title}
            href={service.url}
            name={service.title}
            description={service.description}
          />
        ))}
      </HeaderMenuItemGroup>

      {groups.map((group) => (
        <HeaderMenuItemGroup key={group.title} title={group.title} small>
          {group.options.map((option) => (
            <HeaderMenuItem key={option.title} as="a" href={option.url}>
              {option.title}
            </HeaderMenuItem>
          ))}
        </HeaderMenuItemGroup>
      ))}
    </PlatformMenu>
  )
}

export default HubPlatformMenu
