import './base.scss'

import { StrictMode } from 'react'
import ReactDOM from 'react-dom'
import TagManager from 'react-gtm-module'
import { BrowserRouter } from 'react-router-dom'
import { QueryClientProvider } from 'react-query'
import { datadogLogs } from '@datadog/browser-logs'
import { datadogRum } from '@datadog/browser-rum'
import { AuthProvider, RegionGuard } from '@matillion/hub-client'
import { Toaster } from '@matillion/component-library'

import config from './config'
import './config/i18n.config'
import './config/yup.config'
import { queryClient } from './api/queryClient'

import MainApp from './components/App/MainApp'

if (config.datadog) {
  const { rumService, service, ...options } = config.datadog

  datadogLogs.init({
    ...options,
    service,
    forwardErrorsToLogs: true,
    sampleRate: 100
  })

  datadogRum.init({
    ...options,
    version: config.version,
    service: rumService,
    sampleRate: 100,
    allowedTracingOrigins: [
      // https://regexr.com/7eq8s
      /^https:\/\/.+\.(?:platform|core)(?:-.+)?\.matillion\.com$/
    ]
  })
}

if (config.gtmId) {
  TagManager.initialize({ gtmId: config.gtmId })
}

const root = document.getElementById('root')

ReactDOM.render(
  <StrictMode>
    <Toaster.Provider
      align="right"
      position="bottom"
      theme="light"
      withFade={0}
    >
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <AuthProvider environment={config.authEnvironment}>
            <RegionGuard strictAccountRegion>
              <MainApp />
            </RegionGuard>
          </AuthProvider>
        </BrowserRouter>
      </QueryClientProvider>
    </Toaster.Provider>
  </StrictMode>,
  root,
  () => {
    root?.setAttribute('data-app-environment', config.environment)
    root?.setAttribute('data-app-version', config.version)
  }
)

console.log(
  `%c[PUI] Starting Scheduler UI version %c${config.version}%c in %c${config.environment}`,
  'color: #2D8825;',
  'background-color: #51A620 ; color: white ; font-weight: bold ; ',
  'color: #2D8825;',
  'background-color: #51A620 ; color: white ; font-weight: bold ; '
)

if (process.env.NODE_ENV === 'development') {
  // Only import the PUI console if app running locally
  import(/* webpackChunkName: "pui-dev-chunk" */ './api/mocks/dev-console')
  if (window.location.protocol === 'http:') {
    // Only import the MSW if app running locally and in http
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    import(/* webpackChunkName: "msw-dev-chunk" */ './api/mocks/browser').then(
      async ({ worker }) =>
        worker
          .start({ waitUntilReady: true })
          .then(() => console.log('MSW working!'))
    )
  }
}
