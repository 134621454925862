import { object, string } from 'yup'

export interface CreateScheduleQueryParams {
  correlationId: string
  dataplaneId: string
  dataplaneName: string
  environment: string
  metlEnvironmentName: string
  project: string
  sourceApp: string
  startFlow: string
  username: string
  version: string
}

/* istanbul ignore next */
export const createScheduleRequestSchema = object({
  correlationId: string().required(),
  dataplaneId: string().required(),
  dataplaneName: string().required(),
  environment: string().required(),
  metlEnvironmentName: string().required(),
  project: string().required(),
  sourceApp: string().required(),
  startFlow: string().required(),
  version: string().required(),
  username: string().required()
}).noUnknown(true)
