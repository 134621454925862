import { useTranslation } from 'react-i18next'
import { Button } from '@matillion/component-library'
import { SelectAllButtonProps } from './CheckboxSelector.types'
import { FunctionComponent } from 'react'

import classes from './CheckboxSelector.module.scss'

const SelectAllButton: FunctionComponent<SelectAllButtonProps> = ({
  isAllSelected,
  onClick
}) => {
  const { t } = useTranslation()
  const buttonText = isAllSelected
    ? t('scheduler.select.unselectAll')
    : t('scheduler.select.selectAll')

  return (
    <Button
      className={classes.CheckboxSelector__selectAllButton}
      data-testid="select-all-btn"
      alt="text"
      fontWeight="normal"
      size="md"
      text={buttonText}
      onClick={onClick}
    />
  )
}

export default SelectAllButton
