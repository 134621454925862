import React, { VoidFunctionComponent } from 'react'
import { Button, Modal, Typography } from '@matillion/component-library'
import { MODAL_HEADING_ARIAL_LABEL_BY, ModalHeading } from './ModalHeading'
import { ModalContent } from './ModalContent'
import { ModalActions } from './ModalActions'
import { useTranslation } from 'react-i18next'

export interface GenericModalTypes {
  onCancel: () => void
  onValidate: () => unknown
  heading?: string
  content?: string
  cancel?: string
  validate?: string
  isValidating?: boolean
}

const GenericModal: VoidFunctionComponent<GenericModalTypes> = ({
  onCancel,
  onValidate,
  heading,
  content,
  cancel,
  validate,
  isValidating = false
}) => {
  const { t } = useTranslation()

  return (
    <Modal onCancel={onCancel} ariaLabelledBy={MODAL_HEADING_ARIAL_LABEL_BY}>
      <ModalHeading>
        {heading ?? t('translation:modal.generic.heading')}
      </ModalHeading>
      <ModalContent>
        <Typography format="bcm">
          {content ?? t('translation:modal.generic.content')}
        </Typography>
      </ModalContent>
      <ModalActions>
        <Button
          alt="secondary"
          text={cancel ?? t('translation:modal.generic.cancel')}
          onClick={onCancel}
          data-testid="modal-button-cancel"
        />
        <Button
          data-testid="modal-button-validate"
          alt="red"
          text={validate ?? t('translation:modal.generic.validate')}
          waiting={isValidating}
          onClick={onValidate}
        />
      </ModalActions>
    </Modal>
  )
}

export default GenericModal
