import { Typography } from '@matillion/component-library'
import { FunctionComponent, PropsWithChildren } from 'react'

import classes from './Modal.module.scss'

export const MODAL_HEADING_ARIAL_LABEL_BY = 'modal-title'

export const ModalHeading: FunctionComponent<PropsWithChildren> = ({
  children
}) => {
  return (
    <div className={classes.Modal__Heading}>
      <Typography
        as="h2"
        format="tl"
        id={MODAL_HEADING_ARIAL_LABEL_BY}
        className={classes.Modal__Title}
      >
        {children}
      </Typography>
    </div>
  )
}
