import { addMethod, string } from 'yup'
import {
  hasValidDays,
  hasValidHours,
  hasValidMinutes,
  hasValidMonths,
  hasValidWeekdays,
  isValidCron
} from '../modules/SaaSScheduler/utils/cron.validator'

addMethod(string, 'cron', function (options) {
  const message = 'error.cron.invalid'

  return this.test('isValidCron', message, function (value) {
    const { target, ...cronOptions } = options
    const checkedValue: string = value ?? ''

    if (target === 'hours') return hasValidHours(checkedValue)
    if (target === 'minutes') return hasValidMinutes(checkedValue)
    if (target === 'weekDays') return hasValidWeekdays(checkedValue)
    if (target === 'monthDays') return hasValidDays(checkedValue)
    if (target === 'month') return hasValidMonths(checkedValue)

    return isValidCron(checkedValue, cronOptions)
  })
})
