import { useEffect, useState, VoidFunctionComponent } from 'react'
import { useNavigate } from 'react-router-dom'
import { Typography } from '@matillion/component-library'

import { ScheduleEditorTypes } from '../types'
import SaaSScheduler from '../../SaaSScheduler'
import classes from '../ScheduleEditor.module.scss'
import Error from 'components/Error/Error'

import { AppRoutes } from 'constants/route.constants'
import { useExtractJobFromRouteParams } from 'hooks/useExtractJobFromRouteParams'
import GenericModal from 'components/Modal/GenericModal'
import { useTranslation } from 'react-i18next'

// TODO This is a very experimental behaviour to try and "secure" the form
//  Upon leaving the form (cancel, done[?]) the form URL (including the Job params) will be removed from historu
//  basically preventing the use of "back" in the browser
//  See https://matillion.atlassian.net/browse/DL-341
const REPLACE_HISTORY_ON_LEAVE = true
const defaultFormProps = { dirty: false, prompt: false }

const CreateNewSchedule: VoidFunctionComponent<ScheduleEditorTypes> = () => {
  const { job, errors } = useExtractJobFromRouteParams()
  const [form, setForm] = useState(defaultFormProps)
  const navigate = useNavigate()
  const { t } = useTranslation()

  useEffect(() => {
    if (form.prompt && !form.dirty) {
      navigate(AppRoutes.getDashboard(), {
        replace: REPLACE_HISTORY_ON_LEAVE
      })
    }
  }, [form, navigate])

  if (errors) {
    return (
      <Error
        type={
          <div className={classes.Panel__Error}>
            <Typography format="tsm">{errors?.message}</Typography>
            <div>
              <ul>
                {errors?.errors?.map((e: string, i: number) => (
                  <li key={`key-${i}`}>{e}</li>
                ))}
              </ul>
            </div>
          </div>
        }
      />
    )
  }

  const { ...rest } = job
  return (
    <>
      <SaaSScheduler
        job={rest}
        onClose={(dirty) => {
          setForm({ dirty, prompt: true })
        }}
      />
      {form.prompt && (
        <GenericModal
          validate={t('translation:modal.closeEditor.validate')}
          content={t('translation:modal.closeEditor.content')}
          onCancel={() => setForm(defaultFormProps)}
          onValidate={() =>
            navigate(AppRoutes.getDashboard(), {
              replace: REPLACE_HISTORY_ON_LEAVE
            })
          }
        />
      )}
    </>
  )
}

export default CreateNewSchedule
