// eslint-disable-next-line no-unused-vars
import { object, date, string, number } from 'yup'
import {
  CRON_ALL_VALUES,
  daysOfTheWeekFromSunday
} from './constants/scheduler.constants'
import { isValidCron } from './utils/cron.validator'
import { Days } from './types'

export const ValuesSchema = object({
  name: string().required('error.name.required'),
  scheduleEnabled: string().required('error.scheduleEnabled.required'),
  timezone: object({
    id: string().required(),
    name: string().required()
  })
    .required('error.timezone.required')
    .nullable(),
  hours: number()
    .positive()
    .integer()
    .min(0)
    .max(23)
    .required('error.hours.required'),
  minutes: number()
    .positive()
    .integer()
    .min(0)
    .max(59)
    .required('error.minutes.required'),

  weekDays: string()
    .transform((currentValue) => {
      if (!Array.isArray(currentValue) || currentValue.length === 0) {
        return CRON_ALL_VALUES
      }
      const indexed = currentValue.map((e: Days) =>
        daysOfTheWeekFromSunday.indexOf(e)
      )
      return indexed.join(',')
    })
    .cron({ target: 'weekDays' }),
  monthDays: string()
    .required('error.monthDays.required')
    .cron({ target: 'monthDays' }),
  month: string().required('error.month.required').cron({ target: 'month' }),
  expireOn: date().when('expire', {
    is: true,
    then: date().required('error.expireOn.required')
  })
}).test('check-cron-job-valid', '', function (values) {
  const { minutes, hours, monthDays, month, weekDays } = values
  const cron = `${minutes ?? ''} ${hours ?? ''} ${monthDays ?? ''} ${
    month ?? ''
  } ${weekDays ?? ''}`

  const isValid = isValidCron(cron)
  return (
    isValid ||
    this.createError({
      path: 'cron-job',
      message: 'error.cron.invalid'
    })
  )
})
