import React from 'react'

import { ErrorConstants } from '../../constants/error.constants'
import SchedulerAppPage from '../../components/SchedulerAppPage/SchedulerAppPage'
import Error from '../../components/Error/Error'

const ErrorPage = () => {
  return (
    <SchedulerAppPage>
      <Error type={ErrorConstants.WRONG_PAGE} />
    </SchedulerAppPage>
  )
}

export default ErrorPage
