import { TimezoneListTypes } from '../types'
import { AutoCompleteItemId } from '@matillion/component-library/dist/components/AutoComplete/types'
import { getTimezonesList } from './timezones'

export type TimezoneGrouping = 'byRegion' | 'byUTC' | undefined

interface GroupTransformationType {
  sort: (a: TimezoneListTypes, b: TimezoneListTypes) => number
  getName: (timezone: TimezoneListTypes) => string
}

interface GroupingsDefinitionType {
  byRegion: GroupTransformationType
  byUTC: GroupTransformationType
}

const sortByUTX = (a: TimezoneListTypes, b: TimezoneListTypes): number => {
  const toNumber = (st: string): number => Number(st.replace(':', '.'))

  return toNumber(a.utc) - toNumber(b.utc)
}

export const getGroupedTimezones = (
  grouping: TimezoneGrouping = undefined
): AutoCompleteItemId[] => {
  const timezonesList = getTimezonesList()

  if (!grouping) {
    return [...timezonesList]
      .sort(sortByUTX)
      .map((timezone: TimezoneListTypes) => ({
        id: timezone.tzCode,
        name: timezone.tzCode
      }))
  }

  const apply: GroupingsDefinitionType = {
    byRegion: {
      sort: (a, b) => a.tzCode.localeCompare(b.tzCode),
      getName: (timezone) => {
        const { tzCode } = timezone
        return tzCode.split('/')[0]
      }
    },
    byUTC: {
      sort: (a, b) => a.utc.localeCompare(b.utc),
      getName: (a) => a.utc
    }
  }

  const { sort, getName } = apply[grouping]

  const sortedTZ = [...timezonesList].sort(sort)

  let prevGroupingName = ''
  return sortedTZ.reduce<AutoCompleteItemId[]>((acc, tz) => {
    const groupingName = getName(tz)

    if (prevGroupingName !== groupingName) {
      prevGroupingName = groupingName
      const group: AutoCompleteItemId = {
        id: `group-${groupingName}`,
        name: groupingName,
        disabled: true
      }
      acc.push(group)
    }
    acc.push({
      id: tz.tzCode,
      name: tz.tzCode
    })
    return acc
  }, [])
}
