import { FormikValueTypes } from '../modules/SaaSScheduler'
import { ScheduledETLJobTypes } from '../api/schemas/schedule-etl-job.types'
import { createCronExpression } from '../modules/SaaSScheduler/utils/quartzExpressionFactory'
import { CreateScheduleQueryParams } from 'api/schemas/metl-link.types'

export interface ScheduleRequest {
  job: CreateScheduleQueryParams
  schedule: FormikValueTypes
}

export const makeMutationData = ({
  job,
  schedule
}: ScheduleRequest): ScheduledETLJobTypes => {
  const cronExpression = createCronExpression({ ...schedule })

  return {
    executeRequest: {
      flowIdentifier: {
        environment: job.environment,
        project: job.project,
        startFlow: job.startFlow,
        version: Number(job.version),
        dataplaneId: job.dataplaneId
      },
      metlEnvironmentName: job.metlEnvironmentName
    },
    schedule: {
      cronExpression,
      timezone: schedule.timezone.id,
      scheduleEnabled: schedule.scheduleEnabled,
      name: schedule.name
    }
  }
}
