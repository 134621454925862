import { RoleGuard, useAuth } from '@matillion/hub-client'
import { FunctionComponent } from 'react'
import { schedulerApi } from 'api/scheduler'
import useAuthInterceptor from 'hooks/useAuthInterceptor'

import { Login } from '../../modules/Auth'

import AppMainRouting from './AppMainRouting'
import AppLayout from './AppLayout'

const MainApp: FunctionComponent = () => {
  const { isLoggedIn } = useAuth()
  useAuthInterceptor(schedulerApi)

  if (!isLoggedIn) {
    return <Login />
  }

  return (
    <RoleGuard role="saas-etl:user">
      <AppLayout>
        <AppMainRouting />
      </AppLayout>
    </RoleGuard>
  )
}

export default MainApp
