import { Days } from '../types'

export const daysOfTheWeek: Days[] = [
  'MON',
  'TUE',
  'WED',
  'THU',
  'FRI',
  'SAT',
  'SUN'
]

export const CRON_ALL_VALUES = '*'
export const HOURS_DEFAULT = 0
export const MINUTES_DEFAULT = 0

const makeSundayFirst = (): Days[] => {
  const week = [...daysOfTheWeek]
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const sunday = week.pop()!

  const sundayFirst: Days[] = [sunday, ...week]

  return sundayFirst
}

export const daysOfTheWeekFromSunday = makeSundayFirst()

// TODO Refactor timezone to reflect change on the AutoComplete component
//  See https://bitbucket.org/matillion/matillion-component-library/pull-requests/304
export const DEFAULT_TIMEZONE = {
  id: 'Europe/London',
  name: 'Europe/London'
}
