import { FunctionComponent, PropsWithChildren } from 'react'

import classes from './Modal.module.scss'

export const ModalContent: FunctionComponent<PropsWithChildren> = ({
  children
}) => {
  return (
    <div className={classes.Modal__Content} data-testid="modal-content">
      {children}
    </div>
  )
}
