import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import TRANSLATION_EN_JSON from '../locales/en/translation.json'

// The translations
// todo When implemented, will have to include the translations from the MCL, e.g.
//  import { translation } from '@matillion/component-library'
const resources = {
  en: {
    translation: { ...TRANSLATION_EN_JSON }
  }
}

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: 'en', // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  })
  .then(() => console.dir('i18next: resources loaded', resources))

export default i18n
