import { AuthProviderEnvironment } from '@matillion/hub-client'

enum FeatureFlag {
  PRIVATE_PREVIEW = 'PRIVATE_PREVIEW',
  QUERY_PAYLOAD_BASE64 = 'QUERY_PAYLOAD_BASE64',
  FEATURES_ONLY_FOR_DEBUGGING = 'FEATURES_ONLY_FOR_DEBUGGING',
  SCHEDULE_STATUS_FEATURE = 'SCHEDULE_STATUS_FEATURE',
  SCHEDULE_EDIT_FEATURE = 'SCHEDULE_EDIT_FEATURE',
  SCHEDULE_SEARCH_FILTER_FEATURE = 'SCHEDULE_SEARCH_FILTER_FEATURE'
}

const version = process.env.REACT_APP_VERSION ?? '0.0.0-dev'

export const getEnvFromUrl = (raw: string) => {
  const hostname = new URL(raw).hostname
    .replace('.eu1.', '.')
    .replace('.us1.', '.')
  const currentEnv = hostname
    .split('.')
    ?.reverse()?.[2]
    ?.split('-')
    ?.reverse()?.[0]
  if (['local', 'dev', 'preprod'].includes(currentEnv)) {
    return currentEnv
  } else {
    return 'prod'
  }
}
const environment =
  process.env.REACT_APP_ENVIRONMENT === 'test'
    ? process.env.REACT_APP_ENVIRONMENT
    : getEnvFromUrl(window.location.href)

type EnvironmentConfig = {
  AUTH_ENVIRONMENT: AuthProviderEnvironment
  DATADOG?: Record<string, string>
  FLAGS?: Record<FeatureFlag, string | boolean>
  API_HOST: string
} & {
  [key: string]: string | undefined
}

// eslint-disable-next-line @typescript-eslint/no-var-requires
const envConfig: EnvironmentConfig = require(`./${environment}.json`)

const config = {
  environment,
  version,

  authEnvironment: envConfig.AUTH_ENVIRONMENT,

  gtmId: envConfig.GTM_ID,

  datadog: envConfig.DATADOG && {
    env: envConfig.DATADOG.ENV,
    applicationId: 'f9936726-d6c2-4aca-a1b0-6873cd97301b',
    clientToken: 'pub32d8a412002c87321937075e828a1fe3',
    site: 'datadoghq.eu',
    service: 'scheduler-frontend',
    rumService: 'scheduler-frontend',
    sampleRate: 100
  },

  apiHost: envConfig.API_HOST,
  // TODO: update dev/pre-prod/prod.json to values in https://matillion.atlassian.net/browse/DL-402 once it has been completed
  apiTokenAudience: envConfig.API_TOKEN_AUDIENCE,
  // TODO Better using LaunchDarkly
  flags: envConfig.FLAGS,

  isFlagSet(flag: FeatureFlag): boolean {
    if (this.flags && Object.hasOwn(this.flags, flag)) {
      return !!this.flags[flag]
    }
    return false
  }
}

export default config
export { FeatureFlag }
