export enum QueryKey {
  SCHEDULE_LIST = 'SCHEDULE_LIST',
  CREATE_SCHEDULE = 'CREATE_SCHEDULE',
  DELETE_SCHEDULE = 'DELETE_SCHEDULE',
  EDIT_SCHEDULE = 'EDIT_SCHEDULE'
}

export const QUERY_KEY_ENCODED = 'payload'

export const URL_EDIT_SCHEDULE = '/api/v1/etl/schedule'
export const URL_GET_SCHEDULES = '/api/v1/etl/schedule'
export const URL_CREATE_SCHEDULE = '/api/v1/etl/schedule'
export const URL_DELETE_SCHEDULE = '/api/v2/etl/schedule'

export const QUERY_SMART_RETRIES = 3
export const QUERY_SMART_TIMEOUT = 40 * 1000
