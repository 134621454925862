import React, { VoidFunctionComponent } from 'react'
import { Typography } from '@matillion/component-library'

import { NameViewerTypes } from './types'

export const NameViewer: VoidFunctionComponent<NameViewerTypes> = ({
  nameData
}) => {
  return (
    <Typography as="div" format="bcl">
      {nameData}
    </Typography>
  )
}
