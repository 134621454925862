import { QueryClient } from 'react-query'
import { QUERY_SMART_RETRIES } from '../constants/endpoint.constants'

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 60 * 5, // 5 minutes
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchOnMount: true,
      retry: QUERY_SMART_RETRIES
    }
  }
})
