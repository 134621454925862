import { useMutation, useQueryClient } from 'react-query'
import { QueryKey } from '../constants/endpoint.constants'
import { DeleteScheduleRequest } from 'api/schemas/types'
import { ScheduledETLJobWithIDTypes } from '../api/schemas/schedule-etl-job.types'
import { AxiosError } from 'axios'
import { useNetworkErrorToast } from './useNetworkErrorToast'
import { deleteSchedule } from 'api/scheduler'

const useDeleteSchedule = () => {
  const queryClient = useQueryClient()
  const makeErrorToast = useNetworkErrorToast()

  return useMutation(
    QueryKey.DELETE_SCHEDULE,
    async (schedule: DeleteScheduleRequest) => {
      return deleteSchedule(schedule)
    },
    {
      onSuccess: (_response, variables) => {
        // variables hold the mutation request
        // get the data from the main query cache
        const [queryData] = queryClient.getQueriesData<
          ScheduledETLJobWithIDTypes[]
        >(QueryKey.SCHEDULE_LIST)
        const [, cachedSchedules] = queryData

        // remove the proper schedule from the list
        const matchJobs = cachedSchedules.filter(
          (dataItem: ScheduledETLJobWithIDTypes) =>
            dataItem.executeRequest.flowIdentifier.schedulerID !==
            variables.schedulerID
        )

        // and update the cache
        queryClient.setQueryData(QueryKey.SCHEDULE_LIST, matchJobs)

        // invalidate (mark as stale) but do not refetch immediately
        // noinspection JSIgnoredPromiseFromCall
        queryClient.invalidateQueries(QueryKey.SCHEDULE_LIST, {
          refetchActive: false
        })
      },
      onError: (err: AxiosError) => {
        /* istanbul ignore else */
        if (!err.response?.status) makeErrorToast(err.message)
      }
    }
  )
}

export default useDeleteSchedule
