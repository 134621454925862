import { useTranslation } from 'react-i18next'
import { Toaster } from '@matillion/component-library'

export const useNetworkErrorToast = () => {
  const { t } = useTranslation()
  const { makeToast, clearToasts } = Toaster.useToaster()

  return (message: string, clear = true) => {
    if (clear) clearToasts()
    makeToast({
      title: t('translation:forms.scheduler.submitted_error_title'),
      message: message ?? t('translation:error.query.timeout'),
      type: 'error',
      withFade: true,
      alt: 'red',
      theme: 'dark'
    })
  }
}
