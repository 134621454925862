import cronstrue from 'cronstrue'

export const formatDays = (cronExpressionString: string) => {
  if (cronExpressionString.includes('MON,TUE,WED,THU,FRI,SAT,SUN')) {
    const formattedCronExpression = cronExpressionString.replace(
      'MON,TUE,WED,THU,FRI,SAT,SUN',
      '*'
    )
    return cronstrue.toString(formattedCronExpression, { verbose: true })
  } else {
    return cronstrue.toString(cronExpressionString)
  }
}
